<template>
  <div
    v-if="!isLoading"
    class="flex flex-col gap-32 mb-48 justify-center w-full  items-center"
  >
    <SecaoIntroducao
      class="w-full min-h-[25rem] bg-neutral-80 flex items-center "
      @click:openModalCreateIndicacao="openCreateIndicacao"
    />

    <SecaoSeguroVida class="w-[90%] mx-auto max-w-[1300px]"  @click:openModalCreateIndicacao="openCreateIndicacao"/>
    <q-separator
      class="mb-48 md:mb-24 mx-auto text-neutral-10"
      size="1px"
    ></q-separator>
    <SecaoSeguroSaude class="w-[90%] mx-auto max-w-[1300px]" @click:openModalCreateIndicacao="openCreateIndicacao" />
    <q-separator
      class="mb-48 md:mb-24 mx-auto text-neutral-10"
      size="1px"
    ></q-separator>
    <SecaoConsorcio class="w-[90%] mx-auto max-w-[1300px]" @click:openModalCreateIndicacao="openCreateIndicacao" />
    <q-separator
      class="mb-48 md:mb-24 mx-auto text-neutral-10"
      size="1px"
    ></q-separator>
    <SecaoFinanciamentoImobiliario
      class="w-[90%] mx-auto max-w-[1300px]" @click:openModalCreateIndicacao="openCreateIndicacao"
    />
    <q-separator
      class="mb-48 md:mb-24 mx-auto text-neutral-10"
      size="1px"
    ></q-separator>
    <SecaoHomeEquity class="w-[90%] mx-auto max-w-[1300px]"  @click:openModalCreateIndicacao="openCreateIndicacao"/>
    <q-separator
      class="mb-48 md:mb-24 mx-auto text-neutral-10"
      size="1px"
    ></q-separator>
    <SecaoSegurosMassificados
      class="w-[90%] mx-auto max-w-[1300px]" @click:openModalCreateIndicacao="openCreateIndicacao"
    />
  </div>
  <ModalKanbanIndicacaoCreate />
</template>

<script setup>
import { computed, inject, onBeforeMount } from 'vue'
import GLOBAL from '../../utils/GLOBAL'
import ModalKanbanIndicacaoCreate from '../components/Modal/ModalKanbanIndicacaoCreate.vue'
import SecaoConsorcio from '../components/LandingPage/SecaoConsorcio.vue'
import SecaoFinanciamentoImobiliario from '../components/LandingPage/SecaoFinanciamentoImobiliario.vue'
import SecaoHomeEquity from '../components/LandingPage/SecaoHomeEquity.vue'
import SecaoIntroducao from '../components/LandingPage/SecaoIntroducao.vue'
import SecaoSeguroSaude from '../components/LandingPage/SecaoSeguroSaude.vue'
import SecaoSegurosMassificados from '../components/LandingPage/SecaoSegurosMassificados.vue'
import SecaoSeguroVida from '../components/LandingPage/SecaoSeguroVida.vue'

const { parceiro, data } = inject('context')
const { modalKanbanIndicacaoCreate } = inject('modal')
const isUserLogado = computed(() => data?.id_user && data?.id_user !== 'None')
const setColorsParceiroId = (parceiroID) => {
  const rgbCor = GLOBAL.returnRGB(parceiroID.cor1)
  const rgbCor2 = GLOBAL.returnRGB(parceiroID.cor2)
  document.documentElement.style.setProperty('--primary-pure', rgbCor)
  document.documentElement.style.setProperty('--branding-pure', rgbCor)
  document.documentElement.style.setProperty('--branding-light', rgbCor)
  document.documentElement.style.setProperty('--secondary', rgbCor2)
}

const openCreateIndicacao = () => {
  if (isUserLogado.value) {
    modalKanbanIndicacaoCreate.open()
  } else {
    window.location.href = `${window.location.origin}/login/`
  }
}

onBeforeMount(async () => {
  setColorsParceiroId(parceiro)
})
</script>

<style lang="sass" scoped></style>
