<template>
  <q-header
    class="h-[var(--header-base-height)] !z-[10000] bg-white border-b border-b-neutral-40 bgdark"
  >
    <div class="flex flex-nowrap items-center px-16 h-full gap-16">
      <a href="/">
        <img
          v-if="parceiroID.logo_colorida || parceiro?.logo_colorida"
          :src="parceiroID.logo_colorida || parceiro?.logo_colorida"
          alt="criteria"
          class="logo md:h-24 h-32 max-w-[250px]"
        />
        <svg v-else class="logo md:h-24 max-w-[10rem] h-32 md:max-w-[5rem]">
          <use xlink:href="#icon_logo"></use>
        </svg>
      </a>
      <q-tabs v-model="tab" shrink>
        <q-tab
          v-for="(item, index) in data?.menuList"
          :key="index"
          class="text-neutral-100"
          :name="`tab-${index}`"
          :label="item.title"
          @click="navigatePages(item.href, index)"
        />
      </q-tabs>

      <q-space />

      <div id="BaseHeaderRight"></div>
     <template v-if="isUserLogado">
       <div class="flex gap-8">
        <OButton
          secondary
          class="!border-neutral-40 dark:!border-white/10 !h-48 !px-10 !border !rounded-md hover:bg-neutral-100/10"
        >
          <q-icon
            class="w-26 h-26 dark:!text-white !text-primary-pure"
            name="svguse:#icon_sino"
          ></q-icon>
          <q-menu
            fit
            max-height="600px"
            :offset="[-25, 0]"
            anchor="bottom right"
            self="top middle"
          >
            <BaseHeaderNotificacoes />
          </q-menu>
        </OButton>
      </div>
      <OButton
        secondary
        class="menu-perfil !border-neutral-40 dark:!border-white/10 !border !rounded-md !p-6 hover:bg-neutral-100/10"
      >
        <q-avatar size="2rem" font-size="0.875rem" class="md:hidden">
          <img :src="`${assessor?.foto}` || newUrlImg('images/user.png')" :alt="data?.username" />
        </q-avatar>
        <p class="text-primary-pure capitalize md:hidden dark:text-white">
          {{ data?.username }}
        </p>
        <q-icon
          class="w-32 h-32 opacity-70 dark:!text-white text-primary-pure"
          name="svguse:#icone-arrow-down"
        ></q-icon>
        <q-menu fit>
          <q-list class="min-w-[180px] text-primary-pure font-medium">
            <q-item :href="urls?.termos" class="items-center">
              Políticas e Termos
            </q-item>
             <q-item
              href="/profile/"
              class="items-center"
            >
              Meu Perfil
            </q-item>
            
            <q-item class="items-center hover:text-alert-error" href="/logout/">
              Sair
            </q-item>
          </q-list>
        </q-menu>
      </OButton>
     </template>
    </div>
  </q-header>
</template>

<script setup>
import { computed, inject, onMounted, } from 'vue'
import { parceiroID } from '../../store/parceiro.store'
import { tab } from '../../store/tabMenu.store'
import { useRoute } from 'vue-router'
import BaseHeaderNotificacoes from './BaseHeaderNotificacoes.vue'
import GLOBAL from '../../../utils/GLOBAL'
import OButton from '../Button/OButton.vue'

const {  urls, data, parceiro, assessor } = inject('context')
const route = useRoute()

const { newUrlImg } = GLOBAL

const navigatePages = (link, index) => {
  const newLink = link
 
  
  const url = new URL(newLink, window.location.origin)
  const params = new URLSearchParams(url.search)
  
  params.set('tab_page', `tab-${index}`)
  url.search = params.toString()
  window.location.href = url.toString()
}
const isUserLogado = computed(() => data?.id_user && data?.id_user !== 'None' )
onMounted(() => {
  console.log(window.context, 'context')
  setTimeout(() => { 
    tab.value = route.query?.tab_page
  }, 200)
})
</script>

<style lang="sass">
:root
  --header-base-height: 4rem
  --bg-dark: linear-gradient(90deg, rgba(16, 16, 20, 0.448) 0%, rgba(16, 16, 20, 0.64) 48.36%, rgba(16, 16, 20, 0.48) 98.69%)

.body--dark
  .bgdark
    background: linear-gradient(90deg, rgba(16, 16, 20, 0.448) 0%, rgba(16, 16, 20, 0.64) 48.36%, rgba(16, 16, 20, 0.48) 98.69%) !important
@media (max-width: 475px)
  .logo
    height: 1rem
  .menu-perfil
    display: none
</style>
