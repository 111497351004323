<template>
  <section class="flex flex-col gap-40 mx-auto">
    <div class="grid grid-cols-[1.4fr_1.2fr] md:grid-cols-1 gap-24">
      <CardImageLeft
        :image="newUrlImg('images/home_equity.png')"
        icon-name="svguse:#icon_detail_equity"
      />

      <div class="flex flex-col gap-24">
        <h2 class="text-title-1 text-neutral-100">Home Equity</h2>
        <p class="text-pararaphy-1 text-neutral-70">
          <span class="font-semibold text-neutral-100">Home Equity</span> é uma
          forma inteligente de obter crédito utilizando o seu imóvel quitado
          como garantia. Essa modalidade permite que você tenha acesso a valores
          mais altos com prazos estendidos e taxas de juros mais baixas. Seja
          para investir em um novo negócio, reformar sua casa, ou realizar
          qualquer outro projeto, o Home Equity oferece condições diferenciadas
          e segurança financeira, aproveitando o valor do seu patrimônio.
        </p>

        <div class="flex gap-16 mt-24 md:justify-center">
          <OButton
            primary
            size="md"
            @click="emits('click:openModalCreateIndicacao')"
            >Faça sua indicação à mesa</OButton
          >
          <OButton secondary size="md" icon="svguse:#icon_download"
            >Lâminas de Materiais</OButton
          >
        </div>
      </div>
    </div>
    <div>
      <swiper :space-between="20" slides-per-view="auto">
        <swiper-slide
          v-for="(modalidade, index) in modalidades"
          :key="index"
          class="max-w-[24.5rem] w-[24.5rem] min-w-[24.5rem] p-24"
        >
          <div class="flex flex-col gap-4">
            <h2 class="text-headline-1 text-neutral-100">
              {{ modalidade.title }}
            </h2>
            <span class="text-pararaphy-1 text-neutral-70">
              {{ modalidade.text }}
            </span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import CardImageLeft from './CardImageLeft.vue'
import GLOBAL from '../../../utils/GLOBAL'
import OButton from '../Button/OButton.vue'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const { newUrlImg } = GLOBAL
const emits = defineEmits(['click:openModalCreateIndicacao'])
const modalidades = [
  {
    title: 'Taxas de Juros Reduzidas',
    text: 'Aproveite uma das menores taxas de juros do mercado, ideal para quem busca crédito com custos mais baixos.',
  },
  {
    title: 'Prazos Alongados',
    text: 'Conte com prazos de pagamento que podem chegar a até 20 anos, proporcionando parcelas acessíveis e planejamento financeiro.',
  },
  {
    title: 'Utilização Flexível',
    text: 'Use o crédito para diversas finalidades, como reforma, investimento, educação ou até mesmo para quitar dívidas com juros mais altos.',
  },
  {
    title: 'Acesso a Altos Valores',
    text: 'Obtenha crédito de acordo com o valor do seu imóvel, podendo chegar a até 60% do valor avaliado, ideal para projetos maiores.',
  },
]
</script>

<style lang="scss" scoped></style>
